import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/crm/produk',
        component: () => import('@/layout/CRMProductLayout.vue'),
        redirect: { name: 'BrandView' },
        children: [
            {
                path: 'brand',
                name: 'BrandView',
                component: () => import('@/views/BrandView.vue'),
            },
            {
                path: '',
                name: 'ProductView',
                component: () => import('@/views/ProductView.vue'),
                redirect: { name: 'WaitingApprovalView' },
                children: [
                    {
                        path: 'menunggu-approval',
                        name: 'WaitingApprovalView',
                        component: () => import('@/views/Tab/SubTabProductView.vue'),
                    },
                    {
                        path: 'aktif',
                        name: 'ActiveView',
                        component: () => import('@/views/Tab/SubTabProductView.vue'),
                    },
                    {
                        path: 'buat',
                        name: 'CreateProductView',
                        component: () => import('@/views/CreateEditProductView.vue'),
                    },
                    {
                        path: 'edit',
                        name: 'EditProductView',
                        component: () => import('@/views/CreateEditProductView.vue'),
                    },
                    {
                        path: 'detail',
                        name: 'DetailProductView',
                        component: () => import('@/views/DetailProductView.vue'),
                    },
                ],
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
